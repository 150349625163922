<template>
  <div class="completion-page">
    <Toast position="top-right" />
    <div class="p-text-center p-mb-6">
      <h1 class="trbc-color">Thank you for taking our survey!</h1>
    </div>
    <div class="separator p-my-3">
      <span class="tag"> Remember </span>
    </div>
    <div class="p-text-center p-mb-6">
      <div class="survey-caption survey-caption-sm p-mb-4">
        A <strong>Christ follower</strong> LOVES GOD with all their heart, soul,
        mind, and strength. They do this through DAILY actions of Scripture,
        Prayer, and Worship. Each day they commit to
        <strong>following</strong> Jesus by faith, walking in the power and
        under the control of the <strong>Holy Spirit</strong>. Following Jesus
        means cultivating a culture of prayer and a commitment to reading,
        studying, and obeying <strong>Scripture</strong>. Each day they are
        committed to leveraging all their resources for the gospel of Jesus
        Christ. Every week they are participating in groups and
        <strong>Sunday gatherings</strong> to worship and display the gospel.
      </div>
      <div class="survey-caption survey-caption-sm p-mb-4">
        A <strong>Christ follower</strong> LOVES PEOPLE like Jesus loves them.
        They do this through connecting to a community of believers, a heart for
        serving, and a passion for sharing. They follow Jesus by joining a group
        and using their gifts to serve in the church and community. They
        <strong>pray</strong> and build <strong>relationships</strong> with lost
        people, inviting them to church and sharing their faith story with them.
        They are actively working to make new <strong>disciples</strong> of
        their friends, families, coworkers, and community.
      </div>
      <div class="survey-caption survey-caption-sm">
        We have identified some areas that you have the opportunity to grow in
        below. Take a look at some of the informative videos and resources to
        help you grow in those areas. For more information on what your next
        step at Thomas Road should be, visit
        <a href="https://trbc.org/next">trbc.org/next</a>
      </div>
    </div>
    <div class="separator p-mb-3">
      <span class="tag"> Results </span>
    </div>
    <survey-result class="p-mb-6" :data="data" />
    <div class="separator p-mb-3">
      <span class="tag"> Continue Your Journey </span>
    </div>
    <Card class="center p-shadow-11" style="width: 25em">
      <template #header>
        <img alt="START" src="/assets/start.png" />
      </template>
      <template #title>
        <h3 style="text-align: center">START Book</h3>
      </template>
      <template #subtitle>
        <h5 style="text-align: center">
          START Your New Life. START Following Jesus.
        </h5>
      </template>
      <template #content>
        <p class="p-px-4" style="text-align: center">
          Jesus paid the ultimate price on the cross to free us from our guilt,
          shame, addiction, and ultimately our sin. He is the only one who can
          forgive sin, truly giving us freedom. If you made a decision to follow
          Jesus, we celebrate with you! Or, maybe you want to learn more about
          what faith in Jesus means.
        </p>
      </template>
      <template #footer>
        <div class="p-pb-4" style="text-align: center">
          <Button
            icon="pi pi-directions"
            label="Start Reading"
            class="p-button-secondary"
            style="margin-left: 0.5em"
            @click="clickStartReading"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import SurveyResult from '@/components/SurveyResult.vue'

export default {
  name: 'Completion',
  components: {
    SurveyResult
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const toast = useToast()

    const surveySlug = route.params.surveySlug

    if (store.state.hasTakenSurvey == false) {
      console.log('Has not taken survey, redirecting to /surveys/' + surveySlug)
      router.push({
        path: '/surveys/' + surveySlug + '/'
      })
    }

    const data = reactive(store.state)

    // const clickRetake = function () {
    //   store.dispatch('resetSurveyStatus')
    //   router.push({
    //     path: '/surveys/' + surveySlug + '/'
    //   })
    // }

    // const clickGoToTRBC = function () {
    //   window.location.href = 'https://trbc.org/next'
    // }

    const clickStartReading = function () {
      window.location.href = 'https://trbc.org/start'
    }

    onMounted(() => {
      if (store.state.email) {
        toast.add({
          severity: 'success',
          summary: 'Thank You!',
          detail:
            'You should receive an e-mail if you provided one with a summary of your results as well!',
          life: 3000
        })
      }
    })

    return { data, clickStartReading }
  }
}
</script>

<style scoped>
.completion-page {
  padding: 24px;
}
</style>
